import { ReactNode } from "react";
import {
  NovuProvider,
  PopoverNotificationCenter,
  IMessage,
} from "@novu/notification-center";
import { useEffect } from "react";
import axios from "axios";
import { css } from "@emotion/react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { HiOutlineBell } from "react-icons/hi";
import { Box, Flex } from "rebass/styled-components";
import { BiSolidEnvelope } from "react-icons/bi";
import { GoDotFill } from "react-icons/go";

import { useUser } from "../../Hooks/useUser";
import { AllstarNovu } from "../../State";

export const NovuComponent = ({ isExperiment }: { isExperiment?: boolean }) => {
  const { allstarUser } = useUser();
  const history = useHistory();
  const [allstarNovuParams, setAllstarNovuParams] = useRecoilState(AllstarNovu);

  useEffect(() => {
    async function getNovuSubscriber(token: string) {
      const URL =
        process.env.REACT_APP_ENV !== "production"
          ? "https://gejz7gcvbi.execute-api.us-east-1.amazonaws.com/default/dev-allstar-novu-subscribe"
          : `${process.env.REACT_APP_AWS_SERVERLESS}/notifications`;

      try {
        const response = await axios.get(URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.data) return;

        const { subscriberId, subscriberHash, appId } = response.data;
        if (!subscriberId || !subscriberHash || !appId) return;

        setAllstarNovuParams({
          valid: true,
          subscriberId,
          subscriberHash,
          appId,
        });
      } catch (e) {}
    }
    if (!allstarNovuParams.valid) getNovuSubscriber(allstarUser?.token);
  }, [allstarUser, allstarNovuParams.valid, setAllstarNovuParams]);

  function onNotificationClick(message: IMessage) {
    if (message.payload.shareId) {
      let url = `/clip/${message.payload.shareId}`;

      if (message.templateIdentifier === "new-comment")
        return history.push(`${url}?tab=comments`);

      if (
        message.payload.isMobile === "true" ||
        message.payload.isMobile !== "false"
      ) {
        url = url + "?mobileView=true";
      }

      history.push(url);
    }

    const action = message.payload.action as Record<string, any>;
    if (action && action.active) {
      if (action.external) window.open(action.long);
      if (!action.external) history.push(action.short);
    }

    if (message.templateIdentifier === "match-ready") {
      history.push("/match-history");
    }
  }

  const onNotificationActionClick = (
    templateIdentifier: string,
    type: any,
    message: IMessage,
  ) => {
    if (type === "primary") {
      if (message.payload.shareId) {
        let url = `/clip?clip=${message.payload.shareId}`;
        if (message.payload.isMobile === "true") {
          url = url + "&mobileView=true";
        }
        history.push(url);
      } else if (message.templateIdentifier === "match-ready") {
        history.push("/match-history");
      }
    }
  };

  if (!allstarUser.loggedIn || !allstarNovuParams.valid) return <></>;

  return (
    <Wrapper>
      <NovuProvider
        initialFetchingStrategy={{
          fetchOrganization: true,
          fetchUnseenCount: true,
          fetchUserPreferences: true,
          fetchNotifications: true,
        }}
        styles={AllstarNovuStyles}
        subscriberId={allstarNovuParams.subscriberId}
        subscriberHash={allstarNovuParams.subscriberHash}
        applicationIdentifier={allstarNovuParams.appId}
      >
        <PopoverNotificationCenter
          theme={{ common: { fontFamily: "Barlow" } }}
          colorScheme="dark"
          onActionClick={onNotificationActionClick}
          onNotificationClick={onNotificationClick}
          position={isExperiment ? undefined : "top-end"}
        >
          {({ unseenCount }) => {
            const showBubble = unseenCount && unseenCount > 0;

            return isExperiment ? (
              <Flex style={{ position: "relative" }}>
                <StyledGoDotFill showBubble={!!showBubble} />
                <StyledBiSolidEnvelope />
              </Flex>
            ) : (
              <BellWrapper>
                <CustomBell />
                <CustomCircle showBubble={!!showBubble} />
              </BellWrapper>
            );
          }}
        </PopoverNotificationCenter>
      </NovuProvider>
    </Wrapper>
  );
};

const StyledBiSolidEnvelope = styled(BiSolidEnvelope)`
  color: ${({ theme }) => theme.colors.envy};
  cursor: pointer;
  height: 24px;
  width: 24px;
`;

const StyledGoDotFill = styled(GoDotFill)<{ showBubble: boolean }>`
  color: ${({ theme }) => theme.colors.alert};
  cursor: pointer;
  position: absolute;
  right: -6px;
  top: -3px;
  z-index: 4;

  ${(props) => (props.showBubble ? "display: block" : "display: none")}
`;

const BellWrapper = styled(Box)<any>`
  position: relative;
`;

const CustomBell = styled(HiOutlineBell)`
  color: #f7fcfd;
  width: 24px;
  height: 24px;

  &:hover {
    color: #4dbd94 !important;
    cursor: pointer;
  }
`;

const CustomCircle = styled.div<{ showBubble: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: linear-gradient(
    0deg,
    rgb(255, 81, 47) 0%,
    rgb(221, 36, 118) 100%
  );
  position: absolute;
  left: 14px;
  top: 1px;

  ${(props) => (props.showBubble ? "display: block" : "display: none")}
`;

const Wrapper = styled.div``;

const AllstarNovuStyles = {
  footer: { root: { display: "none" } },
  popover: {
    // position: { position: "top-end" },
    arrow: { display: "none" },
  },
  header: {
    markAsRead: css`
      color: #f7fcfd !important;
      opacity: 1 !important;
      font-weight: 500;
      &:hover {
        color: #4dbd94 !important;
      }
    `,
    cog: css`
      color: #f7fcfd;
      &:hover {
        color: #4dbd94 !important;
      }
    `,
  },
  menu: css`
    background-color: #1f2d38;
  `,

  notifications: {
    listItem: {
      root: css`
        background-color: #1f2d38;
      `,
      unread: css`
        background: unset;
        background-color: #273940;
        &::before {
          background: unset;
          background-color: #4dbd94;
        }
        &:hover {
          background-color: #264747;
        }
      `,
      timestamp: css`
        color: #8fa9af !important;
        opacity: 1;
        font-weight: 500;
      `,
      read: css`
        background: unset;
        background-color: #1f2d38;
        color: #8fa9af !important;
        font-weight: 500;
        &:hover {
          background-color: #1e2b36;
        }
      `,
      dotsButton: css`
        color: #8fa9af !important;
      `,
      buttons: {
        primary: css`
          font-family: "Barlow";
          background: unset;
          background-color: #4dbd94;
          width: 25%;
          border-radius: 999px;
          margin-top: 10px;
          &:hover {
            background: unset;
            background-color: #45aa85;
          }
        `,
        secondary: css`
          font-family: "Barlow";
          background: unset;
          background-color: #4dbd94;
          &:hover {
            background: unset;
            background-color: #4dbd94;
          }
        `,
      },
    },
  },
  actionsMenu: {
    arrow: css`
      background-color: #1f2d38;
      &:hover {
        background-color: #1f2d38;
      }
    `,
    dropdown: css`
      background-color: #1f2d38;
      border: 1px solid rgba(143, 169, 175, 0.2);
      background-color: #1f2d38;
      &:hover {
        background-color: #1f2d38;
      }
    `,
    item: css`
      color: #f7fcfd;
      background-color: #1f2d38;
      &:hover {
        background-color: #141f26;
        color: #4dbd94;
      }
    `,
  },
  unseenBadge: css`
    background: unset;
    background-color: #4dbd94;
    &:hover {
      background: unset;
      background-color: #4dbd94;
    }
  `,
  loader: {
    root: css`
      fill: #4dbd94;
    `,
  },
  layout: {
    root: css`
      font-family: "Barlow";
      margin-left: 10px;
      border: 1px solid rgba(143, 169, 175, 0.2);
      background-color: #1f2d38;
      border-radius: 16px;
      padding: 0px;
      div::-webkit-scrollbar {
        width: 10px;
      }

      div::-webkit-scrollbar-track {
        width: 7px;
        background: #192832;
        border-radius: 4px;
        border-left: 1px solid #20323f;
      }

      div::-webkit-scrollbar-thumb {
        background-color: #213442;
        border-radius: 4px;
      }

      div::-webkit-scrollbar-thumb:hover {
        background-color: #213442;
        cursor: pointer;
      }
    `,
  },
  preferences: {
    root: { fontFamily: "Barlow" },
    item: {
      channels: css`
        background-color: #1f2d38;
        color: #8fa9af !important;
      `,
    },
  },

  accordion: {
    item: css`
      background-color: #1f2d38;
      border: 1px solid rgba(143, 169, 175, 0.2);
      &:hover {
        background-color: #1f2d38;
      }
    `,
    content: css`
      background-color: #1f2d38;
      &:hover {
        background-color: #1f2d38;
      }
    `,
    control: css`
      background-color: #1f2d38;
      &:hover {
        background-color: #1f2d38;
      }
    `,
  },
  switch: {
    root: css`
      input:checked ~ label {
        background: unset;
        background-color: #4dbd94;
      }
    `,
  },
};
