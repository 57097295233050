import { useState } from "react";
import { Box, Flex } from "rebass/styled-components";
import { useGateValue } from "@statsig/react-bindings";

import { InfoSection } from "../InfoSection";
import { Tabs } from "MVVM/Components/Tabs";
import { Divider } from "MVVM/Components/Divider";
import { ShareSection } from "../ShareSection";
import { CampaignContentCallout } from "MVVM/Views/Competitions/Components/CampaignContentCallout";
import { EClipPageSource, IClip } from "MVVM/@types";
import { ClipComments } from "MVVM/Components/Comments/Clip";
import { Feed } from "../Feed";
import { FeedType } from "../@types";
import { CreatorCards } from "../CreatorCards";
import { FollowList } from "../FollowList";
import styled from "styled-components";
import { getQueryParam } from "MVVM/Utilities/QueryParam";

interface ISidebarProps {
  clip: IClip;
  referrer: string | undefined;
  source: EClipPageSource;
}

const Sidebar = ({ clip, referrer, source }: ISidebarProps) => {
  const tabContent = [];
  const clipInCurrentCampaign = clip?.campaign?.isActive;
  const commentsEnabled = useGateValue("comments");
  const [commentCount, setCommentCount] = useState<number | null>(null);
  const commentsDisabled = clip.user?.commentsDisabled;
  const selectedTab = getQueryParam("tab");

  const tabs = {
    comments: {
      component: (
        <ClipComments
          clipId={clip.internalId}
          setCommentCount={setCommentCount}
          disabled={commentsDisabled}
        />
      ),
      name: "comments",
      title: `Comments ${
        !commentsDisabled && Number.isInteger(commentCount)
          ? `(${commentCount})`
          : ""
      }`,
      type: "Comments",
    },
    creatorCards: {
      component: <CreatorCards effects={clip.effects} />,
      name: "creator-cards",
      title: "Creator Cards",
      type: "Creator Cards",
    },
    forYouFeed: {
      component: <Feed type={FeedType.ForYou} referrer={referrer} />,
      name: "recommended",
      title: "Recommended",
      type: "Recommended",
    },
    followList: {
      component: <FollowList players={clip.players} />,
      name: "in-this-match",
      title: "In This Match",
      type: "In This Match",
    },
  };

  switch (source) {
    case EClipPageSource.DIRECT:
      commentsEnabled
        ? tabContent.push(tabs.forYouFeed, tabs.comments, tabs.followList)
        : tabContent.push(tabs.forYouFeed, tabs.followList, tabs.creatorCards);
      break;
    case EClipPageSource.MODAL:
      commentsEnabled
        ? tabContent.push(tabs.comments, tabs.followList, tabs.creatorCards)
        : tabContent.push(tabs.followList, tabs.creatorCards);
      break;
    default:
      break;
  }

  const defaultTab = tabContent.findIndex((tab) => tab.name === selectedTab);

  return (
    <SidebarContainer>
      <InfoSection clip={clip} />
      {clipInCurrentCampaign && (
        <CampaignContentCallout
          compact={true}
          text={clip?.campaign?.marketingCopyClip}
        />
      )}
      <Divider />
      <ShareSection clip={clip} />
      <Divider />
      <ColumnContainer>
        <Tabs tabs={tabContent} active={defaultTab === -1 ? 0 : defaultTab} />
      </ColumnContainer>
    </SidebarContainer>
  );
};

const SidebarContainer = styled(Flex)`
  background: ${({ theme }) => theme.colors.darkerNed};
  flex-direction: column;
  scrollbar-width: none;
  width: 441px;
  z-index: 2;

  @media (max-width: ${(props) => props.theme.breaks.theater}) {
    flex-grow: 1;
    overflow: scroll;
    width: 100%;
  }
`;

const ColumnContainer = styled(Box)`
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  width: 100%;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export { Sidebar };
